// Generated by Framer (d275c2b)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Memoji from "https://framerusercontent.com/modules/PLP5SWQpFPuFrn7tLf3t/suo2OcdmUu5xmjtBXZ7I/dkRfXNUhM.js";
const MemojiFonts = getFonts(Memoji);
const MemojiControls = getPropertyControls(Memoji);

const cycleOrder = ["sWSxijNe_"];

const serializationHash = "framer-6WMis"

const variantClassNames = {sWSxijNe_: "framer-v-q1xtm4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {"Lacey-Mae": "Jj_gJ6raO", Ada: "v2n6jd8d8", Aleeza: "tR7FlThNE", Alejandro: "bNhqIdQoj", Ammaar: "snFARdAmz", Arda: "mcvPgNUgN", Ayisha: "UozSdcuUY", Barry: "TxmZTsvXB", Becca: "NqCoJY2pH", Bo: "UYFenO8zH", Carley: "OY_j7HdJV", Carmen: "nMHdUM1t6", Cheryl: "cHv8JY9g7", Denny: "bOMpuyzc_", Dillon: "tExww8aRk", Elli: "bg0lLngd2", Jardel: "hUi9TraZo", Jason: "AD1uRb789", Jess: "t6uYX0tBZ", Jethro: "ji0e3Gqv5", Lois: "vrPxXX3Sq", Lori: "wQ9whLvFH", Maisie: "YC5psLXzO", Myah: "JG7MxGgTY", Nataniel: "EgLxQrxHD", Nikita: "vol5AdrV3", Olli: "AnI2DvQZf", Patrik: "Stnp3oRU4", Piotr: "FKNQjSL73", Rhian: "y8osi5SA0", Selin: "OYkCSZPL0", Shelbie: "RpMB9X2cU", Siena: "Nz_Z2NTH0", Tim: "rbMcqs1eA"}

const getProps = ({avatar, avatarBackground, background, height, id, width, ...props}) => { return {...props, F0usfKMv0: background ?? props.F0usfKMv0 ?? "var(--token-71349634-3b39-4dae-a5cd-5adff4786465, rgb(239, 237, 255))", QBnwHXXyd: avatarBackground ?? props.QBnwHXXyd ?? "rgb(255, 219, 222)", trm_lMoZe: humanReadableEnumMap[avatar] ?? avatar ?? props.trm_lMoZe ?? "AD1uRb789"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;background?: string;avatar?: keyof typeof humanReadableEnumMap;avatarBackground?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, F0usfKMv0, trm_lMoZe, QBnwHXXyd, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sWSxijNe_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-q1xtm4", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"sWSxijNe_"} ref={ref ?? ref1} style={{backgroundColor: F0usfKMv0, borderBottomLeftRadius: 999, borderBottomRightRadius: 999, borderTopLeftRadius: 999, borderTopRightRadius: 999, ...style}}><ComponentViewportProvider ><motion.div className={"framer-jo0w1v-container"} layoutDependency={layoutDependency} layoutId={"L1ScKZrZn-container"}><Memoji background={QBnwHXXyd} height={"100%"} id={"L1ScKZrZn"} layoutId={"L1ScKZrZn"} radius={60} rotation={0} style={{height: "100%", width: "100%"}} variant={trm_lMoZe} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-6WMis.framer-1pdgjna, .framer-6WMis .framer-1pdgjna { display: block; }", ".framer-6WMis.framer-q1xtm4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 48px; justify-content: center; overflow: hidden; padding: 2px; position: relative; width: 48px; will-change: var(--framer-will-change-override, transform); }", ".framer-6WMis .framer-jo0w1v-container { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-6WMis.framer-q1xtm4 { gap: 0px; } .framer-6WMis.framer-q1xtm4 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-6WMis.framer-q1xtm4 > :first-child { margin-left: 0px; } .framer-6WMis.framer-q1xtm4 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 48
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"F0usfKMv0":"background","trm_lMoZe":"avatar","QBnwHXXyd":"avatarBackground"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramercfeaZYvSQ: React.ComponentType<Props> = withCSS(Component, css, "framer-6WMis") as typeof Component;
export default FramercfeaZYvSQ;

FramercfeaZYvSQ.displayName = "Elements/Avatar circle";

FramercfeaZYvSQ.defaultProps = {height: 48, width: 48};

addPropertyControls(FramercfeaZYvSQ, {F0usfKMv0: {defaultValue: "var(--token-71349634-3b39-4dae-a5cd-5adff4786465, rgb(239, 237, 255)) /* {\"name\":\"Purple-1\"} */", title: "Background", type: ControlType.Color}, trm_lMoZe: MemojiControls?.["variant"] && {...MemojiControls["variant"], defaultValue: "AD1uRb789", description: undefined, hidden: undefined, title: "Avatar"}, QBnwHXXyd: {defaultValue: "rgb(255, 219, 222)", title: "Avatar background", type: ControlType.Color}} as any)

addFonts(FramercfeaZYvSQ, [{explicitInter: true, fonts: []}, ...MemojiFonts], {supportsExplicitInterCodegen: true})